import donationHTML from './donationHtml';
import programHTML from './programHtml';
import './index.scss'

const DEFAULT_HEIGHT = 200;
const DEFAULT_WIDTH = 220;

// Entry point of the library.
function injectDonationsWidget(masjidName, masjidID, elementID, height, width) {
    wrappedInject(
        masjidName, 
        masjidID, 
        elementID, 
        height, 
        width, 
        donationHTML, 
        'musalleen-donations-btn',
        goToPortal(masjidID, 'donation')
    );
}

// Entry point of the library.
function injectProgramsWidget(masjidName, masjidID, elementID, height, width) {
    wrappedInject(
        masjidName, 
        masjidID, 
        elementID, 
        height, 
        width, 
        programHTML, 
        'musalleen-programs-btn',
        goToPortal(masjidID, 'programs')
    );
}

function wrappedInject(
    masjidName, 
    masjidID, 
    elementID, 
    height, 
    width, 
    html, 
    buttonID,
    clickListener
) {
    const injecter = inject(masjidName, masjidID, elementID, height, width, html, buttonID, clickListener)
    // Injecting HTML if the DOM has already loaded.
    if (document.readyState === "complete") {
        injecter();
        return;
    }
    // Injecting HTML when the DOM loads if it hasn't loaded yet.
    document.addEventListener('DOMContentLoaded', injecter);
}

// Actual inject function.
function inject(
    masjidName, 
    masjidID, 
    elementID, 
    height, 
    width, 
    html, 
    buttonID,
    clickListener
) {
    return function () {
        if (!masjidName) {
            throw new Error('masjidName is required for the widget to be injected. Call this like so "MusaleenWidget.inject("<masjid-name>", "<masjid-id>", "<id-of-element>");"');
        }
        if (!masjidID) {
            throw new Error('masjidID is required for the widget to be injected. Call this like so "MusaleenWidget.inject("<masjid-name>", "<masjid-id>", "<id-of-element>");"');
        }
        if (!elementID) {
            throw new Error('elementID for the dom element is required for the widget to be injected. Call this like so "MusaleenWidget.inject("<masjid-name>", "<masjid-id>", "<id-of-element>");"');
        }
        const element = document.getElementById(elementID);
        if (!element) {
            throw new Error(`Invalid elementID given. No such element exists with an id of "${elementID}".`);
        }
        element.innerHTML = createWidget(masjidName, html, height, width);
        attachClickListener(buttonID, clickListener);
    }
}

function createWidget(masjidName, html, height = DEFAULT_HEIGHT, width = DEFAULT_WIDTH) {
    if (typeof height === 'number') {
        height += 'px';
    }
    if (typeof width === 'number') {
        width += 'px';
    }
    return html(masjidName, height, width);
}

function attachClickListener(buttonID, clickListener) {
    const btn = document.getElementById(buttonID);
    if (btn) {
        // Checking so as I don't accidentally attach a listener to an element not yet 
        // present in the dom.
        btn.addEventListener('click', clickListener)
    }
}

function goToPortal(masjidID, portalPathSuffix) {
    return function() {
        const win = window.open(`${process.env.PORTAL_URL}/masjid/${masjidID}/${portalPathSuffix}?from=widget`, '_newtab');
        if (win) {
            win.focus();
        }
    }
}

// Add the widgets right away if this is development.
if (process.env.NODE_ENV === 'development') {
    document.addEventListener('DOMContentLoaded', () => {
        const { body } = document;

        body.innerHTML = createWidget('Masjid Omar.', donationHTML);
        body.innerHTML += '<br /><br />';
        body.innerHTML += createWidget('Masjid Uthman.', programHTML);

        attachClickListener('musalleen-programs-btn', goToPortal('xyz', 'programs'));
        attachClickListener('musalleen-donations-btn', goToPortal('xyz', 'donations'));
    });
}

export {
    injectDonationsWidget as inject,
    injectProgramsWidget as injectPrograms
};