export default (masjidName, height, width) => `
    <div class="widget-container" style="height: ${height};width: ${width}">
        <div class="musalleen-logo"></div>
        <div class="musalleen-heading">
            <span>Programs on</span>
            <span>Musalleen</span>
        </div>
        <div class="musaleen-message">View all of ${masjidName}'s programs and register.</div>
        <div class="musalleen-btn-container">
            <button id="musalleen-programs-btn">
                <div>View Programs</div>
                <div>Click Now to See Programs</div>
            </button>
        </div>
    </div>
`;
