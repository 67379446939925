export default (masjidName, height, width) => `
    <div class="widget-container" style="height: ${height};width: ${width}">
        <div class="musalleen-logo"></div>
        <div class="musalleen-heading">
            <span>Donate via</span>
            <span>Musalleen</span>
        </div>
        <div class="musaleen-message">Musalleen is ${masjidName}'s preferred method of accepting donation/payments.</div>
        <div class="musalleen-btn-container">
            <button id="musalleen-donations-btn">
                <div>Donate</div>
                <div>Click Now to Donate</div>
            </button>
        </div>
    </div>
`;